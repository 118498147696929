// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-classes-index-tsx": () => import("./../../../src/pages/classes/index.tsx" /* webpackChunkName: "component---src-pages-classes-index-tsx" */),
  "component---src-pages-classes-sanity-class-name-tsx": () => import("./../../../src/pages/classes/{SanityClass.name}.tsx" /* webpackChunkName: "component---src-pages-classes-sanity-class-name-tsx" */),
  "component---src-pages-denizens-bokoary-index-tsx": () => import("./../../../src/pages/denizens/bokoary/index.tsx" /* webpackChunkName: "component---src-pages-denizens-bokoary-index-tsx" */),
  "component---src-pages-denizens-creature-index-tsx": () => import("./../../../src/pages/denizens/creature/index.tsx" /* webpackChunkName: "component---src-pages-denizens-creature-index-tsx" */),
  "component---src-pages-denizens-index-tsx": () => import("./../../../src/pages/denizens/index.tsx" /* webpackChunkName: "component---src-pages-denizens-index-tsx" */),
  "component---src-pages-denizens-sanity-denizen-name-tsx": () => import("./../../../src/pages/denizens/{SanityDenizen.name}.tsx" /* webpackChunkName: "component---src-pages-denizens-sanity-denizen-name-tsx" */),
  "component---src-pages-hero-spells-tsx": () => import("./../../../src/pages/hero-spells.tsx" /* webpackChunkName: "component---src-pages-hero-spells-tsx" */),
  "component---src-pages-index-index-tsx": () => import("./../../../src/pages/index/index.tsx" /* webpackChunkName: "component---src-pages-index-index-tsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-pages-index-tsx": () => import("./../../../src/pages/pages/index.tsx" /* webpackChunkName: "component---src-pages-pages-index-tsx" */),
  "component---src-pages-pages-sanity-page-name-tsx": () => import("./../../../src/pages/pages/{SanityPage.name}.tsx" /* webpackChunkName: "component---src-pages-pages-sanity-page-name-tsx" */),
  "component---src-pages-pages-table-first-table-tsx": () => import("./../../../src/pages/pages/table/FirstTable.tsx" /* webpackChunkName: "component---src-pages-pages-table-first-table-tsx" */),
  "component---src-pages-pages-table-second-table-tsx": () => import("./../../../src/pages/pages/table/SecondTable.tsx" /* webpackChunkName: "component---src-pages-pages-table-second-table-tsx" */),
  "component---src-pages-preview-index-tsx": () => import("./../../../src/pages/preview/index.tsx" /* webpackChunkName: "component---src-pages-preview-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-creature-index-tsx": () => import("./../../../src/templates/creature/index.tsx" /* webpackChunkName: "component---src-templates-creature-index-tsx" */)
}

